import * as contractService from '../services/contract'

export const ACTIONS = {
  GET_IN_PROGRESS_CONTRACT_CSUB_REQUEST:
    'GET_IN_PROGRESS_CONTRACT_CSUB_REQUEST',
  GET_IN_PROGRESS_CONTRACT_CSUB_SUCCESS:
    'GET_IN_PROGRESS_CONTRACT_CSUB_SUCCESS',
  GET_IN_PROGRESS_CONTRACT_CSUB_FAILURE:
    'GET_IN_PROGRESS_CONTRACT_CSUB_FAILURE',
  GET_IN_PROGRESS_CONTRACT_SUB_REQUEST: 'GET_IN_PROGRESS_CONTRACT_SUB_REQUEST',
  GET_IN_PROGRESS_CONTRACT_SUB_SUCCESS: 'GET_IN_PROGRESS_CONTRACT_SUB_SUCCESS',
  GET_IN_PROGRESS_CONTRACT_SUB_FAILURE: 'GET_IN_PROGRESS_CONTRACT_SUB_FAILURE',
}

export const GET_IN_PROGRESS_CONTRACT_CSUB_REQUEST = () => ({
  type: ACTIONS.GET_IN_PROGRESS_CONTRACT_CSUB_REQUEST,
})

export const GET_IN_PROGRESS_CONTRACT_CSUB_SUCCESS = (currentContract) => ({
  type: ACTIONS.GET_IN_PROGRESS_CONTRACT_CSUB_SUCCESS,
  currentContract,
})

export const GET_IN_PROGRESS_CONTRACT_CSUB_FAILURE = (err) => ({
  type: ACTIONS.GET_IN_PROGRESS_CONTRACT_CSUB_FAILURE,
  err,
})

export function getInProgressContractCSUB(state) {
  return (dispatch) => {
    dispatch(GET_IN_PROGRESS_CONTRACT_CSUB_REQUEST())

    return contractService.getInProgressContractCSUB(state).then(
      (response) => dispatch(GET_IN_PROGRESS_CONTRACT_CSUB_SUCCESS(response)),
      (err) => dispatch(GET_IN_PROGRESS_CONTRACT_CSUB_FAILURE(err))
    )
  }
}

export const GET_IN_PROGRESS_CONTRACT_SUB_REQUEST = () => ({
  type: ACTIONS.GET_IN_PROGRESS_CONTRACT_SUB_REQUEST,
})

export const GET_IN_PROGRESS_CONTRACT_SUB_SUCCESS = (currentContract) => ({
  type: ACTIONS.GET_IN_PROGRESS_CONTRACT_SUB_SUCCESS,
  currentContract,
})

export const GET_IN_PROGRESS_CONTRACT_SUB_FAILURE = (err) => ({
  type: ACTIONS.GET_IN_PROGRESS_CONTRACT_SUB_FAILURE,
  err,
})

export function getInProgressContractSUB(state) {
  return (dispatch) => {
    dispatch(GET_IN_PROGRESS_CONTRACT_SUB_REQUEST())

    return contractService.getInProgressContractSUB(state).then(
      (response) => dispatch(GET_IN_PROGRESS_CONTRACT_SUB_SUCCESS(response)),
      (err) => dispatch(GET_IN_PROGRESS_CONTRACT_SUB_FAILURE(err))
    )
  }
}
