import * as parametersService from '../services/parameters'

export const ACTIONS = {
  GET_PARAMETERS_REQUEST: 'GET_PARAMETERS_REQUEST',
  GET_PARAMETERS_SUCCESS: 'GET_PARAMETERS_SUCCESS',
  GET_PARAMETERS_FAILURE: 'GET_PARAMETERS_FAILURE',
}

export const GET_PARAMETERS_REQUEST = () => ({
  type: ACTIONS.GET_PARAMETERS_REQUEST,
})

export const GET_PARAMETERS_SUCCESS = (parameters) => ({
  type: ACTIONS.GET_PARAMETERS_SUCCESS,
  parameters,
})

export const GET_PARAMETERS_FAILURE = (err) => ({
  type: ACTIONS.GET_PARAMETERS_FAILURE,
  err,
})

export function getParameters() {
  return (dispatch) => {
    dispatch(GET_PARAMETERS_REQUEST())

    return parametersService.getParameters().then(
      (response) => dispatch(GET_PARAMETERS_SUCCESS(response)),
      (err) => dispatch(GET_PARAMETERS_FAILURE(err))
    )
  }
}
