import { AssociatedProduct } from '../../interfaces/packs/associatedProductInterface'
import { PackAssociation } from '../../interfaces/packs/packAssociationInterface'

export const getAssociatedProducts = (
  associations: PackAssociation[]
): AssociatedProduct[] => {
  const listAssociatedItems = []
  associations?.length > 0 &&
    associations?.forEach((association) => {
      if (association?.products) {
        listAssociatedItems.push(association?.products)
      }
    })
  return listAssociatedItems
}
