import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'

export const getCountries = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.countries,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    isV2: true,
  })
