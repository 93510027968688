import * as promotionsService from '../services/promotionsService'

export const ACTIONS = {
  GET_PROMOTIONS_REQUEST: 'GET_PROMOTIONS_REQUEST',
  GET_PROMOTIONS_SUCCESS: 'GET_PROMOTIONS_SUCCESS',
  GET_PROMOTIONS_FAILURE: 'GET_PROMOTIONS_FAILURE',
  SET_FUTUR_PRICE: 'SET_FUTUR_PRICE',
  SET_CURRENT_PROMOTION: 'SET_CURRENT_PROMOTION',
}

export const GET_PROMOTIONS_REQUEST = () => ({
  type: ACTIONS.GET_PROMOTIONS_REQUEST,
})

export const GET_PROMOTIONS_SUCCESS = (promotions) => ({
  type: ACTIONS.GET_PROMOTIONS_SUCCESS,
  promotions,
})

export const GET_PROMOTIONS_FAILURE = (err) => ({
  type: ACTIONS.GET_PROMOTIONS_FAILURE,
  err,
})

export function getPromotions() {
  return (dispatch) => {
    dispatch(GET_PROMOTIONS_REQUEST())

    return promotionsService.getPromotions().then(
      (response) => dispatch(GET_PROMOTIONS_SUCCESS(response)),
      (err) => dispatch(GET_PROMOTIONS_FAILURE(err))
    )
  }
}

export const SET_FUTUR_PRICE = (price) => ({
  type: ACTIONS.SET_FUTUR_PRICE,
  price,
})

export const SET_CURRENT_PROMOTION = (currentPromotion) => ({
  type: ACTIONS.SET_CURRENT_PROMOTION,
  currentPromotion,
})

export function setCurrentPromotion(currentPromotion: number) {
  return (dispatch) => {
    dispatch(SET_CURRENT_PROMOTION(currentPromotion))
  }
}

export function setFuturePrice(price: number) {
  return (dispatch) => {
    dispatch(SET_FUTUR_PRICE(price))
  }
}
