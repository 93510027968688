import * as countriesServices from '../services/countries'

export const ACTIONS = {
  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',
}

export const GET_COUNTRIES_REQUEST = () => ({
  type: ACTIONS.GET_COUNTRIES_REQUEST,
})

export const GET_COUNTRIES_SUCCESS = (countries) => ({
  type: ACTIONS.GET_COUNTRIES_SUCCESS,
  countries,
})

export const GET_COUNTRIES_FAILURE = (err) => ({
  type: ACTIONS.GET_COUNTRIES_FAILURE,
  err,
})

export function getCountries() {
  return (dispatch) => {
    dispatch(GET_COUNTRIES_REQUEST())

    return countriesServices.getCountries().then(
      (response) => dispatch(GET_COUNTRIES_SUCCESS(response)),
      (err) => dispatch(GET_COUNTRIES_FAILURE(err))
    )
  }
}
